export const getGroupedStyleSettings = (styleSettings) => {
    return styleSettings.reduce((groups, setting) => {
        const defaultName = "Default";
        const groupName = setting.group || defaultName;
        const subGroupName = setting.subGroup || defaultName;
        const secondarySubGroupName = setting.secondarySubGroup || defaultName;

        if (!groups[groupName]) {
            groups[groupName] = {};
        }
        if (!groups[groupName][subGroupName]) {
            groups[groupName][subGroupName] = {};
        }
        if (!groups[groupName][subGroupName][secondarySubGroupName]) {
            groups[groupName][subGroupName][secondarySubGroupName] = [];
        }
        
        groups[groupName][subGroupName][secondarySubGroupName].push(setting);

        return groups;
    }, {});
};
