import * as React from "react"
import Slider from "@mui/material/Slider"
import styled from "styled-components"
import { getPossibleValues } from "../../util/SliderUtil"

const SliderContainer = styled.div`
    height: 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function MinimumDistanceSlider({
    styleSetting,
    updateStyleSettings,
}) {
    const { name, lowerValue, higherValue } = styleSetting
    const possibleValues = getPossibleValues(styleSetting)
    const maxValue = possibleValues.length
    const minDistance = possibleValues[1] - possibleValues[0]

    const handleChange = (event, newValue, activeThumb) => {
        // console.log("newValue", newValue)
        // console.log("activeThumb", activeThumb)
        // console.log("minDistance", minDistance)
        // console.log("maxValue", maxValue)

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], maxValue - minDistance)
                updateStyleSettings(name, [clamped, clamped + minDistance])
            } else {
                const clamped = Math.max(newValue[1], minDistance + 1)
                updateStyleSettings(name, [clamped - minDistance, clamped])
            }
        } else {
            updateStyleSettings(name, newValue)
        }
    }

    return (
        <SliderContainer>
            <Slider
                getAriaLabel={() => "Minimum distance shift"}
                value={[lowerValue, higherValue]}
                onChange={handleChange}
                valueLabelDisplay="off"
                getAriaValueText={(value) => `${value}`}
                disableSwap
                size="small"
                step={1}
                marks
                min={1}
                max={maxValue}
            />
        </SliderContainer>
    )
}
