import React, { useEffect, useRef, useState } from "react"
import "../App.css"
import styled from "styled-components"
import "react-toastify/dist/ReactToastify.css"
import Settings from "./Settings"
import StyleSettings from "./settings/StyleSettings"
import Player from "./Player"
import Navbar from "./Navbar"
import Tracks from "./track/Tracks"
import Controls from "./Controls"
import {
    SECONDARY,
    SECONDARY_4XDIM,
    SECONDARY_HIGHLIGHT,
} from "../style/colors"
import { getGroupedStyleSettings } from "../util/MainUtil"

const MainContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const UpperMain = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-height: 489px) {
        flex-grow: 0;
        height: 112px;
    }
`

const CenterMain = styled.div`
    width: 80%;
    max-width: 768px;
    display: flex;
    justify-content: ${({ isHorizontalScrollVisible }) =>
        isHorizontalScrollVisible ? "flex-start" : "center"};
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    overflow-x: scroll; /* Always show horizontal scrollbar */
    overflow-y: scroll; /* Always show vertical scrollbar */

    /* Custom scrollbar styles for WebKit-based browsers */
    &::-webkit-scrollbar {
        width: 4px; /* Vertical scrollbar width */
        height: 4px; /* Horizontal scrollbar height */
    }

    /* Horizontal scrollbar track and thumb */
    &::-webkit-scrollbar-track:horizontal {
        background: ${({ isHorizontalScrollVisible }) =>
            isHorizontalScrollVisible ? SECONDARY_4XDIM : 'transparent'};
    }
    &::-webkit-scrollbar-thumb:horizontal {
        background: ${({ isHorizontalScrollVisible }) =>
            isHorizontalScrollVisible ? SECONDARY : 'transparent'};
    }
    &::-webkit-scrollbar-thumb:horizontal:hover {
        background: ${({ isHorizontalScrollVisible }) =>
            isHorizontalScrollVisible ? SECONDARY_HIGHLIGHT : 'transparent'};
    }

    /* Vertical scrollbar track and thumb */
    &::-webkit-scrollbar-track:vertical {
        background: ${({ isVerticalScrollVisible }) =>
            isVerticalScrollVisible ? SECONDARY_4XDIM : 'transparent'};
    }
    &::-webkit-scrollbar-thumb:vertical {
        background: ${({ isVerticalScrollVisible }) =>
            isVerticalScrollVisible ? SECONDARY : 'transparent'};
    }
    &::-webkit-scrollbar-thumb:vertical:hover {
        background: ${({ isVerticalScrollVisible }) =>
            isVerticalScrollVisible ? SECONDARY_HIGHLIGHT : 'transparent'};
    }
`

const LowerMain = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-height: 489px) {
        flex-grow: 0;
        height: 112px;
    }
`

function Main({
    songs,
    setSelectedSong,
    selectedSong,
    selectedInstruments,
    setSelectedInstruments,
    selectInstrument,
    setSongName,
    isNameUnique,
    createAndFetchMidi,
    toggleLeftSideBar,
    toggleShowStyleSettings,
    showStyleSettings,
    styleSettings,
    updateStyleSettings,
    handleVolumeChange,
}) {
    const initialSongCreatedRef = useRef(false)
    const [isHorizontalScrollVisible, setIsHorizontalScrollVisible] = useState(false)
    const [isVerticalScrollVisible, setIsVerticalScrollVisible] = useState(false)
    const centerMainRef = useRef(null)
    const [expandedStyleSettingGroups, setExpandedStyleSettingGroups] = useState({})

    useEffect(() => {
        if (songs.length === 0 && !initialSongCreatedRef.current) {
            createAndFetchMidi()
            initialSongCreatedRef.current = true // Update the ref immediately
        }
    }, [songs.length]) // Dependency on songs.length

    const calculateTracksHeight = () => {
        return selectedInstruments.length * 32
    }

    const calculateStyleSettingsHeight = () => {
        let totalHeight = 0;

        for (const [groupName, subGroups] of Object.entries(groupedStyleSettings)) {
            totalHeight += 32; // 32px for the group name

            if (expandedStyleSettingGroups[groupName]?.expanded) {
                for (const [subGroupName, secondarySubGroups] of Object.entries(subGroups || {})) {
                    if (subGroupName !== "Default") {
                        totalHeight += 32; // 32px for the subgroup name, unless it's "Default"
                    }

                    if (expandedStyleSettingGroups[groupName][subGroupName]?.expanded || subGroupName === "Default") {
                        for (const [secondarySubGroupName, settings] of Object.entries(secondarySubGroups || {})) {
                            if (secondarySubGroupName !== "Default") {
                                totalHeight += 32; // 32px for the secondary subgroup name, unless it's "Default"
                            }

                            if (expandedStyleSettingGroups[groupName][subGroupName]?.[secondarySubGroupName] || secondarySubGroupName === "Default") {
                                totalHeight += (settings?.length || 0) * 64; // 64px per expanded setting
                            }
                        }
                    }
                }
            }
        }

        return totalHeight;
    }

    const toggleStyleSettingsGroup = (groupName, subGroupName = null, secondarySubGroupName = null) => {
        setExpandedStyleSettingGroups((prev) => {
            const newState = { ...prev };

            if (secondarySubGroupName) {
                newState[groupName] = {
                    ...newState[groupName],
                    [subGroupName]: {
                        ...newState[groupName][subGroupName],
                        [secondarySubGroupName]: !newState[groupName][subGroupName][secondarySubGroupName],
                    },
                };
            } else if (subGroupName) {
                newState[groupName] = {
                    ...newState[groupName],
                    [subGroupName]: {
                        ...newState[groupName][subGroupName],
                        expanded: !newState[groupName][subGroupName]?.expanded,
                    },
                };
            } else {
                newState[groupName] = {
                    ...newState[groupName],
                    expanded: !newState[groupName]?.expanded,
                };
            }

            return newState;
        });
    }

    const groupedStyleSettings = getGroupedStyleSettings(styleSettings);

    const checkScrollVisibility = () => {
        if (!centerMainRef.current) {
            return
        }

        const height = showStyleSettings ? calculateStyleSettingsHeight() : calculateTracksHeight()
        const availableHeight = centerMainRef.current.clientHeight
        const availableWidth = centerMainRef.current.clientWidth 

        console.log("height", height)
        console.log("availableHeight", availableHeight)

        const isVerticalOverflowing = height > availableHeight
        setIsVerticalScrollVisible(isVerticalOverflowing)
        
        const isHorizontalOverflowing = availableWidth < 480
        setIsHorizontalScrollVisible(isHorizontalOverflowing)
    }

    useEffect(() => {
        if (centerMainRef.current) {
            checkScrollVisibility()
        }
    }, [expandedStyleSettingGroups, centerMainRef.current, showStyleSettings])

    useEffect(() => {
        const handleResize = () => {
            setTimeout(() => {
                checkScrollVisibility();
            }, 100);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [showStyleSettings, expandedStyleSettingGroups]);

    return (
        <MainContainer>
            <UpperMain>
                <Navbar selectedSong={selectedSong} />
                <Settings
                    selectedInstruments={selectedInstruments}
                    selectInstrument={selectInstrument}
                    selectedSong={selectedSong}
                    setSongName={setSongName}
                    isNameUnique={isNameUnique}
                    styleSettings={styleSettings}
                    updateStyleSettings={updateStyleSettings}
                />
            </UpperMain>
            {selectedSong && (
                <CenterMain
                    ref={centerMainRef}
                    isHorizontalScrollVisible={isHorizontalScrollVisible}
                    isVerticalScrollVisible={isVerticalScrollVisible}
                >
                    {showStyleSettings ? (
                        <StyleSettings
                            updateStyleSettings={updateStyleSettings}
                            isVerticalScrollVisible={isVerticalScrollVisible}
                            isHorizontalScrollVisible={isHorizontalScrollVisible}
                            expandedStyleSettingGroups={expandedStyleSettingGroups}
                            groupedStyleSettings={groupedStyleSettings}
                            toggleStyleSettingsGroup={toggleStyleSettingsGroup}
                        />
                    ) : (
                        <Tracks
                            selectedInstruments={selectedInstruments}
                            selectInstrument={selectInstrument}
                            isVerticalScrollVisible={isVerticalScrollVisible}
                            isHorizontalScrollVisible={isHorizontalScrollVisible}
                            handleVolumeChange={handleVolumeChange}
                            setSelectedInstruments={setSelectedInstruments}
                        />
                    )}
                </CenterMain>
            )}
            <LowerMain>
                <Player
                    songs={songs}
                    setSelectedSong={setSelectedSong}
                    selectedInstruments={selectedInstruments}
                    selectedSong={selectedSong}
                    createAndFetchMidi={createAndFetchMidi}
                />
                <Controls
                    songs
                    setSelectedSong
                    selectedSong={selectedSong}
                    selectedInstruments={selectedInstruments}
                    createAndFetchMidi={createAndFetchMidi}
                    toggleLeftSideBar={toggleLeftSideBar}
                    toggleShowStyleSettings={toggleShowStyleSettings}
                />
            </LowerMain>
        </MainContainer>
    )
}

export default Main
