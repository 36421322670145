import React from "react"
import Button from "@mui/material/Button"
import { alpha } from "@mui/system"
import {
    BUTTON_FONT_FAMILY,
    BUTTON_FONT_WEIGHT,
    BUTTON_LETTER_SPACING,
    FONT_SIZE_2XS,
} from "../style/fontSettings"
import { SECONDARY_HIGHLIGHT, MELOGY_TRANSPARENT } from "../style/colors"
import {
    getButtonColor,
    getButtonHoverColor,
    getButtonActiveColor,
} from "../util/ButtonUtil"
import styled from "styled-components"

const StyledText = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: ${({ textAlign }) => textAlign || "left"};
    padding-left: 2px;
    padding-top: 4px;
`

const MelogyButton = ({
    text,
    height,
    width,
    onClick,
    justifyContent,
    fontSize,
    fontFamily,
    fontWeight,
    letterSpacing,
    textTransform,
    isSelected,
    buttonColor,
    icon,
    margin,
    isToggleSidebarButton,
    textAlign,
}) => {
    const color = getButtonColor(buttonColor, isSelected)
    const colorHover = getButtonHoverColor(buttonColor)
    const colorActive = getButtonActiveColor(buttonColor)
    const [isTouched, setIsTouched] = React.useState(false)

    const mobileColor = isTouched ? colorActive : color
    const mobileBackgroundColor = isTouched ? alpha(colorHover, 0.2) : "inherit"

    return (
        <Button
            onClick={onClick}
            onTouchStart={() => setIsTouched(true)}
            onTouchEnd={() => setIsTouched(false)}
            startIcon={icon ? icon : null}
            sx={{
                fontFamily: fontFamily || BUTTON_FONT_FAMILY,
                fontSize: fontSize || FONT_SIZE_2XS,
                letterSpacing: letterSpacing || BUTTON_LETTER_SPACING,
                fontWeight: fontWeight || BUTTON_FONT_WEIGHT,
                justifyContent: justifyContent,
                width: width,
                minWidth: width,
                height: height,
                minHeight: height,
                color: color,
                backgroundColor:
                    isSelected && !text ? alpha(colorHover, 0.2) : "inherit",
                padding: 0,
                margin: margin || 0,
                borderRadius: 0,
                borderRadius: text || isToggleSidebarButton ? 0 : "50%",
                borderBottom:
                    isSelected && text
                        ? `2px solid ${SECONDARY_HIGHLIGHT}`
                        : `2px solid ${MELOGY_TRANSPARENT}`,
                textTransform: textTransform || "none",
                transition: "color 0.3s ease, background-color 0.3s ease",

                "& .MuiButton-startIcon": {
                    margin: "auto",
                    width: "32px",
                    minWidth: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },

                // Desktop (non-mobile) styles
                "&:hover": text
                    ? {
                          color: colorHover,
                          backgroundColor: alpha(colorHover, 0.2),
                      }
                    : {
                          color: color, // maintain original color on hover
                          backgroundColor: isSelected
                              ? alpha(colorHover, 0.2)
                              : "inherit", // maintain original background color on hover
                      },

                // Mobile styles
                "@media (hover: none)": {
                    color: mobileColor,
                    backgroundColor:
                        isSelected && !text
                            ? alpha(colorHover, 0.2)
                            : mobileBackgroundColor,
                    "&:hover": {
                        color: mobileColor, // Restore original color
                        backgroundColor: mobileBackgroundColor,
                    },
                },

                "&:focus": {
                    outline: "none",
                },
            }}
        >
            {text && <StyledText textAlign={textAlign}>{text}</StyledText>}
        </Button>
    )
}

export default MelogyButton
