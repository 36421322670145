export const getDisplayName = (name) => {
    const nameMap = {
        songStructure: "form",
        tempoAdjustment: "tempo adjustment",
        intensityOfChords: "chord rate",
        intensityOfMelody: "melody rate",
        motiveComplexityOfMelody: "melody motive complexity",
        motiveComplexityOfChords: "chord motive complexity",
        chordRepetitionForPart: "chord repetition for part",
        chordRepetitionForMelody: "chord repetition for melody",
        chordRepetitionForMotive: "chord repetition for motive",
        majorChordRate: "major chord rate",
        minorChordRate: "minor chord rate",
        diminishedChordRate: "diminished chord rate",
        augmentedChordRate: "augmented chord rate",
    }
    return nameMap[name] || name
}

export const getKeySetting = (styleSettings) => {
    return styleSettings.find((setting) => setting.name === "key")
}

export const getKey = (styleSettings) => {
    return getKeySetting(styleSettings).value
}

export const getKeys = (styleSettings) => {
    return getKeySetting(styleSettings).possibleValues
}

export const getKeyAsInt = (styleSettings) => {
    const keys = getKeys(styleSettings)
    const key = getKey(styleSettings)

    return keys.indexOf(key)
}

export const getRoots = (styleSettings) => {
    return styleSettings.find((setting) => setting.name === "root")
        .possibleValues
}

export const getRoot = (styleSettings) => {
    return styleSettings.find((setting) => setting.name === "root").value
}
