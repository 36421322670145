import React from "react"
import styled from "styled-components"
import { FaAngleDown, FaAngleRight } from "react-icons/fa"
import { MELOGY_TRANSPARENT, SECONDARY, SECONDARY_4XDIM, SECONDARY_3XDIM, SECONDARY_5XDIM } from "../../style/colors"
import StyleSetting from "./StyleSetting"
import Button from "../Button"

const SettingsContainer = styled.div`
    width: 100%; //calc(100% - 16px);
    height: 100%;
    max-width: 960px;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ isVerticalScrollVisible }) =>
        isVerticalScrollVisible ? "flex-start" : "center"};
`

const SettingsBackground = styled.div`
    width: 100%;
    height: 100%;
    color: ${SECONDARY};
    background-color: ${MELOGY_TRANSPARENT};
`

const SettingsGroup = styled.div`
    margin-left: 0;
`

const SettingsSubGroup = styled.div`
    padding-left: 16px;
    margin-left: 16px;
    border-left: 1px solid ${SECONDARY_3XDIM};
`

const SettingsSecondarySubGroup = styled.div`
    padding-left: 16px;
    margin-left: 16px;
    border-left: 1px solid ${props => props.leftBorderColor};
`

function StyleSettings({
    updateStyleSettings,
    isVerticalScrollVisible,
    isHorizontalScrollVisible,
    expandedStyleSettingGroups,
    groupedStyleSettings,
    toggleStyleSettingsGroup,
}) {
    return (
        <SettingsContainer 
            isVerticalScrollVisible={isVerticalScrollVisible} 
            isHorizontalScrollVisible={isHorizontalScrollVisible}
        >
            <SettingsBackground>
                {Object.entries(groupedStyleSettings).map(([groupName, subGroups]) => (
                    <SettingsGroup key={groupName}>
                        <Button
                            text={groupName}
                            height="32px"
                            width="168px"
                            justifyContent="flex-start"
                            buttonColor="secondary"
                            onClick={() => toggleStyleSettingsGroup(groupName)}
                            icon={expandedStyleSettingGroups[groupName]?.expanded ? 
                                <FaAngleDown style={{ fontSize: "16px" }} /> : 
                                <FaAngleRight style={{ fontSize: "16px" }} />
                            }
                        />
                        {expandedStyleSettingGroups[groupName]?.expanded && Object.entries(subGroups).map(([subGroupName, secondarySubGroups]) => (
                            subGroupName === "Default" ? (
                                Object.entries(secondarySubGroups).map(([secondarySubGroupName, settings]) => (
                                    secondarySubGroupName === "Default" ? (
                                        settings.map((styleSetting) => (
                                            <StyleSetting
                                                key={styleSetting.name}
                                                styleSetting={styleSetting}
                                                updateStyleSettings={updateStyleSettings}
                                                leftBorderColor={SECONDARY_3XDIM}
                                            />
                                        ))
                                    ) : (
                                        <SettingsSecondarySubGroup key={secondarySubGroupName} leftBorderColor={SECONDARY_3XDIM}>
                                            <Button
                                                text={secondarySubGroupName}
                                                height="32px"
                                                width="168px"
                                                justifyContent="flex-start"
                                                buttonColor="secondary"
                                                onClick={() => toggleStyleSettingsGroup(groupName, subGroupName, secondarySubGroupName)}
                                                icon={expandedStyleSettingGroups[groupName][subGroupName][secondarySubGroupName] ? 
                                                    <FaAngleDown style={{ fontSize: "16px" }} /> : 
                                                    <FaAngleRight style={{ fontSize: "16px" }} />
                                                }
                                            />
                                            {expandedStyleSettingGroups[groupName][subGroupName][secondarySubGroupName] && settings.map((styleSetting) => (
                                                <StyleSetting
                                                    key={styleSetting.name}
                                                    styleSetting={styleSetting}
                                                    updateStyleSettings={updateStyleSettings}
                                                    leftBorderColor={SECONDARY_4XDIM}
                                                />
                                            ))}
                                        </SettingsSecondarySubGroup>
                                    )
                                ))
                            ) : (
                                <SettingsSubGroup key={subGroupName} leftBorderColor={SECONDARY_3XDIM}>
                                    <Button
                                        text={subGroupName}
                                        height="32px"
                                        width="168px"
                                        justifyContent="flex-start"
                                        buttonColor="secondary"
                                        onClick={() => toggleStyleSettingsGroup(groupName, subGroupName)}
                                        icon={expandedStyleSettingGroups[groupName][subGroupName]?.expanded ? 
                                            <FaAngleDown style={{ fontSize: "16px" }} /> : 
                                            <FaAngleRight style={{ fontSize: "16px" }} />
                                        }
                                    />
                                    {expandedStyleSettingGroups[groupName][subGroupName]?.expanded && Object.entries(secondarySubGroups).map(([secondarySubGroupName, settings]) => (
                                        secondarySubGroupName === "Default" ? (
                                            settings.map((styleSetting) => (
                                                <StyleSetting
                                                    key={styleSetting.name}
                                                    styleSetting={styleSetting}
                                                    updateStyleSettings={updateStyleSettings}
                                                    leftBorderColor={SECONDARY_4XDIM}
                                                />
                                            ))
                                        ) : (
                                            <SettingsSecondarySubGroup key={secondarySubGroupName} leftBorderColor={SECONDARY_4XDIM}>
                                                <Button
                                                    text={secondarySubGroupName}
                                                    height="32px"
                                                    width="168px"
                                                    justifyContent="flex-start"
                                                    buttonColor="secondary"
                                                    onClick={() => toggleStyleSettingsGroup(groupName, subGroupName, secondarySubGroupName)}
                                                    icon={expandedStyleSettingGroups[groupName][subGroupName][secondarySubGroupName] ? 
                                                        <FaAngleDown style={{ fontSize: "16px" }} /> : 
                                                        <FaAngleRight style={{ fontSize: "16px" }} />
                                                    }
                                                />
                                                {expandedStyleSettingGroups[groupName][subGroupName][secondarySubGroupName] && settings.map((styleSetting) => (
                                                    <StyleSetting
                                                        key={styleSetting.name}
                                                        styleSetting={styleSetting}
                                                        updateStyleSettings={updateStyleSettings}
                                                        leftBorderColor={SECONDARY_5XDIM}
                                                    />
                                                ))}
                                            </SettingsSecondarySubGroup>
                                        )
                                    ))}
                                </SettingsSubGroup>
                            )
                        ))}
                    </SettingsGroup>
                ))}
            </SettingsBackground>
        </SettingsContainer>
    )
}

export default StyleSettings
